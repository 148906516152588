// -- The Link Resolver
// This function will be used to generate links to Prismic documents
// As your project grows, you should update this function according to your routes

exports.readTime = function readTime(body) {
    const onlyText = body.filter(item => item.type === 'text');
    const text = onlyText.map(item => item.primary.text.map(p => p.text)).toString();
  // Route for blog posts
  const wordsPerMinute = 200; // Average case.
  const textLength = text.split(" ").length; // Split by words
  if(textLength > 0) {
    const value = Math.ceil(textLength / wordsPerMinute);
    return `~${value} min de citit`;
  } else {
      return '';
  }
}
