import React from 'react'
import Link from 'gatsby-link'
import Img from 'gatsby-image'

export default ({ article }) => {
    if (!article) return null;

    return (
        <div>
            <Link to={article.slug}>
                <div className="similar-posts">
                    <div className="similar-posts-image">
                        <Img fluid={article.image} alt={article.title}/>
                    </div>
                    <div className="similar-posts-content">
                        <h3>{article.title}</h3>
                        <h4>{article.categoryName}</h4>
                        <p>{article.description}</p>
                    </div>
                </div>
            </Link>
        </div>
    )
}
