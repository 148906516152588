import React, { useEffect, useState, useRef } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { RichText } from 'prismic-reactjs'
import Layout from '../components/layouts'
import SEO from '../components/SEO'
import SimilarArticles from '../components/SimilarArticles'
import ShareButtons from '../components/ShareButtons'
import { ImageCaption, Quote, Text, Embed } from '../components/slices'
import { DiscussionEmbed } from "disqus-react";
import moment from 'moment';
import 'moment/locale/ro';
import {readTime} from '../utils/readTime';
import uparrow from '../images/up-arrow.svg'


// Query for the Blog Post content in Prismic
export const query = graphql`
query BlogPostQuery($uid: String) {
  prismic{
    allPosts(uid: $uid){
      edges{
        node{
          _meta{
            id
            uid
            type
            tags
          }
          title
          date
          hero_image
          hero_imageSharp {
            childImageSharp {
                fluid(quality: 100) {
                    base64
                    aspectRatio
                    sizes
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    originalName
                }
            }
          }
          category {
            _linkType
            ... on PRISMIC_Categories {
              categories_page_title
              _linkType
              _meta {
                uid
              }
            }
          }
          author {
            ... on PRISMIC_Author {
                name
                image
                imageSharp {
                    childImageSharp {
                        fluid(quality: 100) {
                            base64
                            aspectRatio
                            sizes
                            src
                            srcSet
                            srcWebp
                            srcSetWebp
                            originalName
                        }
                    }
                }
            }
          }
          body{
            __typename
            ... on PRISMIC_PostBodyText{
              type
              label
              primary{
                text
              }
            }
            ... on PRISMIC_PostBodyQuote{
              type
              label
              primary{
                quote
              }
            }
            ... on PRISMIC_PostBodyImage_with_caption{
              type
              label
              primary{
                image
                imageSharp {
                    childImageSharp {
                        fluid(quality: 100) {
                            base64
                            aspectRatio
                            sizes
                            src
                            srcSet
                            srcWebp
                            srcSetWebp
                            originalName
                        }
                    }
                }
                caption
              }
            }
            ... on PRISMIC_PostBodyEmbed{
              type
              label
              primary{
                embed_link
              }
            }
          }
        }
      }
    }
  }
}
`

// Sort and display the different slice options
const PostSlices = ({ slices }) => {
    return slices.map((slice, index) => {
        const res = (() => {
            switch (slice.type) {
                case 'text': return (
                    <div key={index} className="homepage-slice-wrapper">
                        {<Text slice={slice} />}
                    </div>
                )

                case 'quote': return (
                    <div key={index} className="homepage-slice-wrapper">
                        {<Quote slice={slice} />}
                    </div>
                )

                case 'image_with_caption': return (
                    <div key={index} className="homepage-slice-wrapper">
                        {<ImageCaption slice={slice} />}
                    </div>
                )

                case 'embed': return (
                    <div key={index} className="homepage-slice-wrapper">
                        {<Embed slice={slice} />}
                    </div>
                )

                default: return
            }
        })();
        return res;
    })
}

const getTags = tags => {
    if (tags.length) {
        return (
            <div className="post-tags">
                {tags.map((item, i) => {
                    const itemSlug = item.split(' ').join('-');
                    return (
                    <a key={i} href={`/tag/${itemSlug}`}># {item}</a>
                )})}


            </div>
        )
    }
    return false
}

const firstParagraph = (post => {

    // Find the first text slice of post's body
    let firstTextSlice = post.body.find(slice => slice.type === 'text');
    if (firstTextSlice != null) {
        // Set the character limit for the text we'll show in the homepage
        const textLimit = 100
        let text = RichText.asText(firstTextSlice.primary.text)
        let limitedText = text.substring(0, textLimit)

        if (text.length > textLimit) {
            // Cut only up to the last word and attach '...' for readability
            return (
                limitedText.substring(0, limitedText.lastIndexOf(' ')) + '...'
            );
        } else {
            // If it's shorter than the limit, just show it normally
            return text;
        }
    } else {
        // If there are no slices of type 'text', return nothing
        return null;
    }
})

// Display the title, date, and content of the Post
const PostBody = ({ blogPost, showTopScroll }) => {
    const titled = blogPost.title.length !== 0;
    const disqusShortname = "cesavizitam-ro";

    // url: typeof window !== 'undefined' && window.location.href,
    const disqusConfig = {
        identifier: blogPost._meta.id,
        title: blogPost.title[0].text,
    }
    const categoryTitle = blogPost.category && blogPost.category.categories_page_title &&
        blogPost.category.categories_page_title[0] && blogPost.category.categories_page_title[0].text;
    const categoryURL = blogPost.category && blogPost.category._meta.uid;
    // const imageHero = blogPost.hero && blogPost.hero.url;

    moment.locale("ro");
    moment.createFromInputFallback = function (config) {
        // unreliable string magic, or
        config._d = new Date(config._i);
    };

    return (
        <div>
            {/* <div className="breadcrumbs"> */}
                {/* <div className="back">
            <Link to="/">inapoi</Link>
          </div> */}
                {/* <div className="category">
            {categoryTitle && <h5><Link to={categoryURL}>{categoryTitle}</Link> / {blogPost.title[0].text}</h5>}
          </div> */}
            {/* </div> */}
            <div className="post-header" id="post-header">
            <div className="post-hero">
            {/* <ReactImageProcess
                mode="waterMark"
                waterMarkType="image"
                waterMark={watermark}
                width={200}
                height={200}
                opacity={1}
                coordinate={[450, 500]}
            > */}

                <Img
                    fluid={blogPost.hero_imageSharp.childImageSharp.fluid}
                    alt={RichText.asText(blogPost.title)}
                />
                {/* <img crossOrigin="Anonymous" src={ imageHero } alt={ blogPost.title } /> */}
            {/* </ReactImageProcess> */}
            </div>
                {/* Render the edit button */}
                <h1 data-wio-id={blogPost._meta.id}>
                        {titled ? RichText.asText(blogPost.title) : 'Untitled'}
                    </h1>
                
                {showTopScroll && <img alt="scroll to top" src={uparrow} className="scrolltop" id="scrolltop" onClick={() => {
                    if (window !== 'undefined') {
                        window.scrollTo({top: 0, behavior: 'smooth'})
                    }
                }}/>}

            <div className="post-meta">
                        <Link to={categoryURL}>{categoryTitle}</Link><span></span>
                        {`${moment(blogPost.date).format('LL')}`}<span></span>
                        {readTime(blogPost.body)}<span></span>
                        <Img fluid={blogPost.author.imageSharp.childImageSharp.fluid}
                            alt={blogPost.author.name[0].text} className="author"/>
                        {blogPost.author.name[0].text}
            </div>
            </div>
            {/* Go through the slices of the post and render the appropiate one */}
            <PostSlices slices={blogPost.body} />
            {getTags(blogPost._meta.tags)}
            <ShareButtons title={blogPost.title[0].text} tags={blogPost._meta.tags} url={`https://www.cesavizitam.ro/${blogPost._meta.uid}`} twitterHandle={'cesavizitam.ro'}/>
            <SimilarArticles category={categoryURL} tags={blogPost._meta.tags} currentArticleSlug={blogPost._meta.uid}/>
            <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
        </div>
    );
}

export default (props) => {
    // Define the Post content returned from Prismic
    const doc = props.data.prismic.allPosts.edges.slice(0, 1).pop();

    if (!doc) return null;
    const blogPost = doc.node;
    const [showTopScroll, setShowTopScroll] = useState(false);
    const topScroll = useRef();
    const setTopScroll = data => {
        setShowTopScroll(data);
        topScroll.current = data;
    }

    const scrollListener = () => {
        const currentVisibility = topScroll.current;
        if (!currentVisibility && window.scrollY > 400) {
            setTopScroll(true);
        } else if (currentVisibility && window.scrollY < 400) {
            setTopScroll(false);
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', scrollListener);
        return () => {
            window.removeEventListener('scroll', scrollListener);
        }
    }, [])

    return (
        <Layout>
            <SEO
                title={blogPost.title[0].text}
                description={firstParagraph(blogPost)}
                image={blogPost.hero_image.url}
                pathname={blogPost._meta.uid}
                article
            />
            <PostBody blogPost={blogPost} showTopScroll={showTopScroll} />
        </Layout>
    )
}
