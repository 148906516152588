import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from "gatsby"
import { RichText } from 'prismic-reactjs'
import { SimilarArticlesFactory } from './SimilarArticlesFactory'
import ArticleCard from './ArticleCard'
// import "../styles/SimilarArticles.sass"

const SimilarArticles = ({ articles }) => (
    <div className="similar-articles">
        <h3>Vă recomandăm</h3>
        <section className="similar-articles-section">
            {articles.map((article, i) => (
            <ArticleCard {...article} key={i}/>
            ))}
        </section>
    </div>
)

const firstParagraph = (post => {

    // Find the first text slice of post's body
    let firstTextSlice = post.body.find(slice => slice.type === 'text');
    if (firstTextSlice != null) {
        // Set the character limit for the text we'll show in the homepage
        const textLimit = 100
        let text = RichText.asText(firstTextSlice.primary.text)
        let limitedText = text.substring(0, textLimit)

        if (text.length > textLimit) {
            // Cut only up to the last word and attach '...' for readability
            return (
                limitedText.substring(0, limitedText.lastIndexOf(' ')) + '...'
            );
        } else {
            // If it's shorter than the limit, just show it normally
            return text;
        }
    } else {
        // If there are no slices of type 'text', return nothing
        return null;
    }
})

const query = graphql`
query SimilarArticles {
  prismic{
        allPosts {
            edges {
                node{
                    _meta {
                        id
                        uid
                        tags
                    }
                    title
                    category {
                        _linkType
                        ... on PRISMIC_Categories {
                            categories_page_title
                             _linkType
                            _meta {
                                uid
                            }
                        }
                    }
                    hero_image
                    hero_imageSharp {
                        childImageSharp {
                            fluid(quality: 100) {
                                base64
                                aspectRatio
                                sizes
                                src
                                srcSet
                                srcWebp
                                srcSetWebp
                                originalName
                            }
                        }
                    }
                    body{
                        __typename
                        ... on PRISMIC_PostBodyText{
                          type
                          label
                          primary{
                            text
                          }
                        }
                    }
                }
            }
        }
    }
}
`
// (1.) Query for articles
export default (props) => (
    <StaticQuery
        query={`${query}`}
        render={data => {
            const { category, tags, currentArticleSlug } = props;

            // (2.) Marshall the response into articles
            const articles = data.prismic.allPosts.edges.map(x => {
                const article = {};
                article.slug = x.node._meta.uid;
                article.tags = x.node._meta.tags;
                article.category = x.node.category._meta.uid;
                article.categoryName = x.node.category.categories_page_title[0].text;
                article.title = x.node.title[0].text;
                article.image = x.node.hero_imageSharp.childImageSharp.fluid;
                article.description = firstParagraph(x.node);
                return article;
            });

            // (3.) Use a SimilarArticlesFactory to get my similar articles
            const similarArticles = new SimilarArticlesFactory(
                articles, currentArticleSlug
            )
                .setMaxArticles(2)
                .setCategory(category)
                .setTags(tags)
                .getArticles()

            // (4.) Render it
            return (
                <SimilarArticles
                    articles={similarArticles}
                />
            )
        }}
    />
)